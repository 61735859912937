import React, {
  createContext,
  useState,
  useReducer,
  useRef,
} from 'react';

import useCurrentLocation from '../custom-hooks/useCurrentLocation';
import selectedChoiceReducer from '../reducers/selectedChoiceReducer';
import useFetchDataPoints from '../services/useFetchDataPoints';

const DEFAULT_SELECTED_CHOICE = {
  service: [],
  channel: [],
  bankingAgent: [],
};

export const MapFilterDataContext = createContext<{
  [key: string]: any;
}>({
  selectedChoice: DEFAULT_SELECTED_CHOICE,
  dispatchSelectedChoice: () => { },
  dataPoints: [],
  fetchDataPoints: () => { },
  mapRef: null, // todo change to any ref?
  keyword: undefined,
  handleSetKeyword: () => { }
});

interface MapFilterDataContextProviderProps { }

const MapFilterDataContextProvider: React.FC<MapFilterDataContextProviderProps> = (
  props,
) => {
  // keyword used in search and filter pins
  const [keyword, setKeyword] = useState<string | undefined>(undefined) // TODO: reconsider using null
  const [selectedChoice, dispatchSelectedChoice] = useReducer(
    selectedChoiceReducer,
    DEFAULT_SELECTED_CHOICE,
  );
  const {
    dataPoints,
    fetchData: fetchDataPoints,
  } = useFetchDataPoints();
  const mapRef = useRef(null);

  const handleSetKeyword = (keyword: string | undefined) => {
    setKeyword(keyword)
  }

  return (
    <MapFilterDataContext.Provider
      value={{
        selectedChoice,
        dispatchSelectedChoice,
        dataPoints,
        fetchDataPoints,
        mapRef,
        keyword,
        handleSetKeyword
      }}
    >
      {props.children}
    </MapFilterDataContext.Provider>
  );
};

export default MapFilterDataContextProvider;
