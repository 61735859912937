import React, { createContext, useState } from 'react'

export const InputFocusContext = createContext({
  isFocus: false,
  handleSetIsFocus: (value: boolean) => { }
})

const InputFocusContextProvider: React.FC = ({ children }) => {
  const [isFocus, setIsFocus] = useState(false)
  const handleSetIsFocus = (value: boolean) => {
    setIsFocus(value)
  }
  return (
    <InputFocusContext.Provider value={{ isFocus, handleSetIsFocus }}>
      {children}
    </InputFocusContext.Provider>
  )
}

export default InputFocusContextProvider
