import React from 'react';

import { Avatar } from '@material-ui/core';
import styled from 'styled-components/macro';

interface ToggleableButtonProps {
  rounded?: boolean;
  isSelected?: boolean;
  onToggle: Function;
  label: string;
  imageSrc?: string;
  disabled?: boolean;
}

const ToggleableButton: React.FC<ToggleableButtonProps> = ({
  rounded = true,
  isSelected,
  onToggle,
  imageSrc,
  label,
  disabled,
}) => {
  return (
    <SButton
      className={`${rounded ? 'rounded' : ''} ${
        disabled ? 'disabled' : 'clickable'
      } ${isSelected ? 'is-selected' : ''}`}
      onClick={() => (disabled ? null : onToggle())}
    >
      {imageSrc ? (
        <SAvatar src={imageSrc} />
      ) : (
        <SAvatar className={isSelected ? 'is-selected' : ''}>
          {' '}
          {label[0]}
        </SAvatar>
      )}
      <SLabel>{label}</SLabel>
    </SButton>
  );
};

export default ToggleableButton;

const SAvatar = styled(Avatar)`
  &.MuiAvatar-root {
    width: 25px;
    height: 25px;
    font-size: 10px;
  }
  margin-right: 5px;
`;

const SButton = styled.div`
  &.rounded {
    border-radius: 1rem;
  }
  border: solid 1px ${(props) => props.theme.colors.grey.medium};
  color: ${(props) => props.theme.colors.palette.primary};
  display: flex;
  padding: 3px;
  align-items: center;
  margin-right: 6px;
  &.is-selected {
    background: ${(props) => props.theme.colors.palette.primary};
    color: #fff; // TODO: change to text white?
  }
  &.disabled {
    opacity: ${(props) => props.theme.disabled.opacity};
  }
`;

const SLabel = styled.div`
  font-size: ${(props) => props.theme.fontSizes.small};
  margin-right: 8px;
`;
