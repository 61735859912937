import React, {
  createContext,
  useState,
  useMemo,
  useEffect,
} from 'react';

export const LoadingContext = createContext<{ [key: string]: any }>({
  handleSetServiceLoading: () => { },
  isAnyServiceLoading: false,
});
const LoadingContextProvider: React.FC = (props) => {
  const [serviceLoading, setServiceLoading] = useState<{
    [key: string]: boolean;
  }>({});
  const isAnyServiceLoading = useMemo(
    () =>
      Object.values(serviceLoading).some(
        (loading: boolean) => loading,
      ),
    [serviceLoading],
  );
  console.debug('serviceLoading', serviceLoading);
  const handleSetServiceLoading = (
    service: string,
    loading: boolean,
  ) => {
    console.debug('handleSetServiceLoading', loading);
    setServiceLoading({
      ...serviceLoading,
      [service]: loading,
    });
  };

  return (
    <LoadingContext.Provider
      value={{
        handleSetServiceLoading,
        isAnyServiceLoading,
      }}
    >
      {props.children}
    </LoadingContext.Provider>
  );
};

export default LoadingContextProvider;
