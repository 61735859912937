import React, { useContext, useState, useEffect } from 'react';

import { LangContext } from '../contexts/LangContext';
import { LoadingContext } from '../contexts/LoadingContext';
import { getDataPointsAroundCenter } from '../mock/dataPoints';
import QueryModel from '../models/QueryModel';
import { FilterContext } from '../contexts/FilterContext';
import branch from '../images/branch.png';
import axios from 'axios';
import initApiInstance from './initApiInstance';
import FilterModel from '../models/FilterModel';

const selectedChoicesHasAny = (selectdChoices: {
  [key: string]: string[];
}) => {
  return Object.values(selectdChoices).some(
    (choices: string[]) => choices.length > 0,
  );
};

const useFetchDataPoints = () => {
  const { lang } = useContext(LangContext);
  const [dataPoints, setDataPoints] = useState<Array<any>>([]);
  const { handleSetServiceLoading } = useContext(LoadingContext);
  const { filterModel } = useContext(FilterContext);
  const apiInstance = initApiInstance();

  useEffect(() => {
    handleSetServiceLoading('useFetchDataPoints', true);
  }, []);

  const addPinIconsToPoints = (data: any[]) => {
    return data.map((d) => {
      const pinIcon =
        (filterModel &&
          filterModel.getImageUrlOf(d.pinFilterName, 'pin')) ||
        branch;
      return {
        ...d,
        pinIcon,
      };
    });
  };

  const fetchData = async (
    center: { lat: number; lng: number },
    selectedChoice = {} as any,
    search: string | undefined
  ) => {
    await new Promise(async (resolve) => {
      handleSetServiceLoading('useFetchDataPoints', true);
      // so no points displayed when still fetching data
      setDataPoints([]);
      let data = [];
      // TODO: has keyword or any select any service
      if (selectedChoicesHasAny(selectedChoice) || search) {
        const addSToSelectedChoice = FilterModel.formatSelectedChoiceToHaveSectionNamesAsKeys(
          selectedChoice,
        );
        const params = { ...center, ...addSToSelectedChoice, lang, search };
        const queryParams = QueryModel.toQueryString(params);
        console.debug('useFetchDataPoints fetchData: ', queryParams);
        data = await apiInstance
          .get(`locators/find?${queryParams}`)
          .then((res) => {
            console.log(`service keyword: `, res.data);
            return res.data;
          });
      }
      //TODO : sort asc by distance
      const formatted = addPinIconsToPoints(data);
      formatted.sort((a: any, b: any) => { return a.distance - b.distance })
      handleSetServiceLoading('useFetchDataPoints', false);
      setDataPoints(formatted);
      resolve(formatted);
    });
  };

  return { dataPoints, fetchData };
};

export default useFetchDataPoints;
