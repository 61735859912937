const theme = {
  // note: haven't added he disabled to other theme
  disabled: {
    opacity: 0.9,
  },
  colors: {
    common: {
      white: '#fff',
      black: '#000',
    },
    text: {
      primary: '#576D70',
      grey: '#cbcbcb',
    },
    palette: {
      primary: '#3C4043',
      secondary: '#00A94F',
      lightPrimary: '#c5dcec',
    },
    background: {
      primary: '#fff',
    },
    grey: {
      medium: '#808080',
      light: '#cbcbcb',
    },
  },
  fontFamilies: {
    body: `Prompt, sans-serif`,
    title: `Prompt, sans-serif`,
  },
  fontWeights: {
    body: 300,
    title: 500,
  },
  fontSizes: {
    extraSmall: '0.2rem',
    small: '0.8rem',
    smallMedium: '1rem', // not in other theme yet
    medium: '1.2rem',
    big: '1.8rem',
  },
};

export default theme;
