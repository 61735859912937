import React, { createContext } from 'react';

import useFetchAllMenuSections from '../services/useFetchAllMenuSections';

export const FilterContext = createContext({
  filterModel: null as any,
  fetchAllMenuSections: () => {},
});

const FilterContextProvider = (props: any) => {
  const {
    filterModel,
    fetchData: fetchAllMenuSections,
  } = useFetchAllMenuSections();
  return (
    <FilterContext.Provider
      value={{ filterModel, fetchAllMenuSections }}
    >
      {props.children}
    </FilterContext.Provider>
  );
};

export default FilterContextProvider;
