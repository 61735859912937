import axios from 'axios';
import { promises } from 'fs';

const initApiInstance = () => {
  const param = {
    baseURL: process.env.REACT_APP_API_URL,
  };
  const apiInstance = axios.create(param);
  return apiInstance;
};
export default initApiInstance;
