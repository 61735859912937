import React, { useState, useContext, useEffect } from 'react'
import initApiInstance from './initApiInstance';
import { LoadingContext } from '../contexts/LoadingContext';

// const getMockedData = (keyword: string) => new Array(20).fill(null).map((each: any, index: number) => `${keyword}${index}`)

const useFetchSuggestions = () => {
  const apiInstance = initApiInstance();
  const [data, setData] = useState<string[]>([])
  const [isLoading, setIsLoading] = useState(false)

  const fetchData = async (keyword: string) => {
    new Promise(async (resolve) => {
      let updatedData: string[] = []
      if (!keyword) {
        setData(updatedData)
        resolve(updatedData);
      } else {
        // TODO: change this to call api instead
        setIsLoading(true)
        setTimeout(async() => {
          updatedData = await apiInstance
          .get(`locators/suggestion?keyword=${keyword}`)
          .then((res) => {
            console.log(`suggest keyword: `, res.data);
            return res.data;
          });
          setData(updatedData);
          setIsLoading(false)
          resolve(updatedData);
        },)
      }
    });
  }

  return ({ data, fetchData, isLoading })
}

export default useFetchSuggestions
