import queryString from 'query-string';

class QueryModel {
  static toQueryString(obj: { [key: string]: any }) {
    return queryString.stringify(obj, { arrayFormat: 'comma' });
  }
  static parse(text: string) {
    return queryString.parse(text, { arrayFormat: 'comma' });
  }
}

export default QueryModel;
