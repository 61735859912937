interface DataPoint { lat: number, lng: number }

/**
 * assuming we have google loaded
 * @param points
 */
const computeAverageDistance = (points: DataPoint[], zoom?: number) => {
  const total = points.reduce((cur, point, index) => {
    const newDist = index + 1 < points.length ? google.maps.geometry.spherical.computeDistanceBetween(
      new google.maps.LatLng(points[index].lat, points[index].lng),
      new google.maps.LatLng(points[index + 1].lat, points[index + 1].lng),
    ) : 0;
    return cur + newDist
  }, 0)
  return total / points.length
}

const distanceInPx = (map: any, point1: DataPoint, point2: DataPoint) => {
  const p1 = map.getProjection().fromLatLngToPoint(new google.maps.LatLng({ lat: point1.lat, lng: point1.lng }));
  const p2 = map.getProjection().fromLatLngToPoint(new google.maps.LatLng({ lat: point2.lat, lng: point2.lng }));

  const pixelSize = Math.pow(2, -map.getZoom());

  const d = Math.sqrt((p1.x - p2.x) * (p1.x - p2.x) + (p1.y - p2.y) * (p1.y - p2.y)) / pixelSize;
  return d;
}

const computeAverageDistanceInPx = (map: any, points: DataPoint[]) => {
  const total = points.reduce((cur, point, index) => {
    const newDist = index + 1 < points.length ? distanceInPx(map,
      points[index],
      points[index + 1]
    ) : 0;
    return cur + newDist
  }, 0)
  return total / points.length
}

/**
 * if very zoomed in (>=15) show all
 * else if average distance is very close (< 155) (not sure the unit) then random (arbitary chance number here)
 * else (probably not that close) show all
 * @param map
 * @param points
 */
const getRandomPointsToShow = (map: any, points: DataPoint[]): [DataPoint[], number] => {
  const bounds = map.getBounds();
  // only count those in bound
  const inbound = points.filter(
    (dp: { lat: number; lng: number }) =>
      bounds.contains(new google.maps.LatLng(dp.lat, dp.lng)),
  );
  const avgDist = computeAverageDistanceInPx(map, points)
  const zoom = map.getZoom()
  let chance = 0
  if (zoom >= 15) {
    chance = 1
  } else if (avgDist < 160) {
    chance = (avgDist / 1500)
  } else {
    chance = 1
  }
  console.debug('getRandomPointsToShow: chance', chance, ' avgDist ', avgDist, ' zoom ', zoom)
  const filtered = inbound.filter(p => Math.random() <= chance)
  console.debug('BEFORE', points.length, ' INBOUND ', inbound.length, ' RANDOM ', filtered.length)
  return [filtered, chance]

}

/**
 * for no random
 * return only points in inbound
 * @param map
 * @param points
 */
const getPointsToShow = (map: any, points: DataPoint[]): [DataPoint[], number] => {
  const bounds = map.getBounds();
  // only count those in bound
  const inbound = points.filter(
    (dp: { lat: number; lng: number }) =>
      bounds.contains(new google.maps.LatLng(dp.lat, dp.lng)),
  );
  const chance = 1
  return [inbound, chance]
}


export default { computeAverageDistance, computeAverageDistanceInPx, getRandomPointsToShow, getPointsToShow }
