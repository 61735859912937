import React, { useState, useMemo, createContext } from 'react';

import { ThemeProvider } from 'styled-components/macro';

import commonTheme from './common';
import darkTheme from './dark';
import kplusLight from './kplusLight';
import lightTheme from './light';

interface ThemeProps {
  mode?: string;
}

const DEFAULT_MODE = 'kplus-light';
export const DEFAULT_BREAKPOINT = 620;

export const ThemeContext = createContext({
  mode: DEFAULT_MODE,
  handleSetThemeMode: (newMode: string) => {},
});

const ThemeContextProvider: React.FC<ThemeProps> = ({
  children,
  mode: modeProps = DEFAULT_MODE,
}) => {
  const [mode, setMode] = useState(modeProps);
  const theme = useMemo(() => {
    let theme1;
    if (mode === 'light') theme1 = lightTheme;
    else if (mode === 'dark') theme1 = darkTheme;
    else if (mode === 'kplus-light') theme1 = kplusLight;
    return { ...commonTheme, ...theme1 };
  }, [mode]);
  const handleSetThemeMode = (newMode: string): void => {
    setMode(newMode);
  };
  return (
    <ThemeContext.Provider value={{ mode, handleSetThemeMode }}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </ThemeContext.Provider>
  );
};

export default ThemeContextProvider;
