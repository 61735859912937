import React, {
  createContext,
  useState,
  useEffect,
  useMemo,
} from 'react';

import { useSwipeable, Swipeable } from 'react-swipeable';

export const MultiDrawerStateContext = createContext<{
  [key: string]: any;
}>({
  drawerStateMap: {},
  clickHandleHandler: () => { },
  swipeHandlers: () => { },
  closeToNextStateHandler: () => { },
  collapseDrawer: () => { },
  collapseAllDrawer: () => { },
  setDrawerStateMap: () => { },
  combinedDrawerState: 'NORMAL',
  handleSetDrawerState: () => { },
});
interface MultiDrawerStateContextProviderProps {
  drawerStateMap: { [key: string]: string };
}

// drawerState = COLLAPSED, NORMAL, MORE

const getNextDrawerState = (curState: string, dir: string) => {
  if (dir === 'Up') {
    if (curState === 'NORMAL') {
      return 'MORE';
    } else if (curState === 'COLLAPSED') {
      return 'NORMAL';
    }
  } else if (dir === 'Down') {
    if (curState === 'NORMAL') {
      return 'COLLAPSED';
    } else if (curState === 'MORE') {
      return 'NORMAL';
    }
  }
  return 'NORMAL';
};

const getNextDrawerStateWithoutDir = (curState: string) => {
  switch (curState) {
    case 'NORMAL':
      return 'MORE';
    case 'COLLAPSED':
      return 'NORMAL';
    case 'MORE':
      return 'NORMAL';
    default:
      return 'NORMAL';
  }
};

const stateScore = {
  MORE: 3,
  NORMAL: 2,
  COLLAPSED: 1,
} as { [key: string]: number };

const scoreState = Object.keys(stateScore).reduce(
  (acc: any, state: string) => ({
    ...acc,
    [stateScore[state]]: state,
  }),
  {},
);

const getCloseNextDrawerStateWithoutDir = (curState: string) => {
  switch (curState) {
    case 'NORMAL':
      return 'COLLAPSED';
    case 'COLLAPSED':
      return 'COLLAPSED';
    case 'MORE':
      return 'NORMAL';
    default:
      return 'NORMAL';
  }
};

const MultiDrawerStateContextProvider: React.FC<MultiDrawerStateContextProviderProps> = (
  props: any,
) => {
  const [drawerStateMap, setDrawerStateMap] = useState<{
    [key: string]: string;
  }>(props.drawerStateMap || {});
  // when props.drawerState change
  // useEffect(() => {
  //   // TODO: double check if these props are actually used
  //   if (props.drawerStateMap) setDrawerStateMap(props.drawerStateMap);
  // }, [props.drawerStateMap]);

  /**
   * set drawerName to value state but set others to COLLAPSED
   * @param drawerName
   * @param value
   */
  const handleSetDrawerState = (
    drawerName: string,
    value: string,
  ) => {
    // const updatedState = { ...drawerStateMap, [drawerName]: value };

    // propsed version set state of this drawer to value but set other drawer to COLLAPSED
    const updatedState = Object.keys(drawerStateMap).reduce((acc, name: string) => ({ ...acc, [name]: name === drawerName ? value : 'COLLAPSED' }), {})
    setDrawerStateMap(updatedState);
    // TODO: double check if this props are actually used
    if (props.onDrawerStateChange) {
      props.onDrawerStateChange(updatedState);
    }
  };

  const swipeHandler = (drawerName: string, event: any) => {
    console.debug('swiped', event.dir);
    const drawerState = drawerStateMap[drawerName];
    const nextState = getNextDrawerState(drawerState, event.dir);
    handleSetDrawerState(drawerName, nextState);
  };
  const swipeHandlers = (drawerName: string) => ({
    onSwiped: (event: any) => swipeHandler(drawerName, event),
  })

  const clickHandleHandler = (drawerName: string) => {
    const drawerState = drawerStateMap[drawerName];
    const nextState = getNextDrawerStateWithoutDir(drawerState);
    handleSetDrawerState(drawerName, nextState);
  };
  const closeToNextStateHandler = (drawerName: string) => {
    const drawerState = drawerStateMap[drawerName];
    const nextState = getCloseNextDrawerStateWithoutDir(drawerState);
    handleSetDrawerState(drawerName, nextState);
  };

  const collapseDrawer = (drawerName: string) => {
    handleSetDrawerState(drawerName, 'COLLAPSED');
  };

  const collapseAllDrawer = () => {
    setDrawerStateMap(
      Object.keys(drawerStateMap).reduce(
        (acc: any, state: string) => ({
          ...acc,
          [state]: 'COLLAPSED',
        }),
        {},
      ),
    );
  };

  const getScoreFromState = (drawer: string) => {
    return stateScore[drawer];
  };

  const getStateFromScore = (score: number) => {
    return scoreState[score];
  };

  /**
   * if any more, return more
   * if normal and less, return normal
   * if all less, return less
   */
  const combinedDrawerState = useMemo(() => {
    console.debug('drawerStateMap', drawerStateMap);
    const score = Math.max(
      ...Object.values(drawerStateMap).map((name: string) =>
        getScoreFromState(name),
      ),
    );
    return getStateFromScore(score);
  }, [drawerStateMap]);

  return (
    <MultiDrawerStateContext.Provider
      value={{
        drawerStateMap,
        clickHandleHandler,
        swipeHandlers,
        closeToNextStateHandler,
        setDrawerStateMap,
        collapseDrawer,
        combinedDrawerState,
        handleSetDrawerState,
        collapseAllDrawer,
      }}
    >
      {props.children}
    </MultiDrawerStateContext.Provider>
  );
};

export default MultiDrawerStateContextProvider;
