import React, {
  createContext,
  useState,
  useLayoutEffect,
  useEffect,
} from 'react';

import { useHistory, useLocation } from 'react-router-dom';

export const DEFAULT_LANG = 'th';

interface LangContextProviderProps { }
interface LangContextValue {
  lang: string;
  currentLangData: { [key: string]: string };
  switchLang: (ln: string) => void;
}

export const LangContext = createContext<LangContextValue>({
  lang: '', // for currently used language
  currentLangData: {},
  switchLang: (ln: string) => { },
});

const langData = {
  en: {
    channel: 'Channel',
    channels: 'Channels',
    service: 'Service',
    services: 'Services',
    internationalTradeCenter: 'Internaltional Trade Center',
    backAgent: 'BANK AGENT',
    branch: 'Branch',
    atmCdm: 'ATM/CDM',
    theWisdom: 'THE WISDOM',
    foreignExchangeBooth: 'Foreign Exchange Booth',
    smeBusinessCenter: 'SME Business Center',
    withdrawal: 'Withdrawal',
    verification: 'Verification',
    foreignExchange: 'Foreign Exchange',
    smeServices: 'SME Services',
    kCheckIdKiosks: 'K-Check ID Kiosks',
    bankingAgents: 'Banking Agents',
    pttAmazon: 'PTT Amazon',
    kerry: 'Kerry',
    bigC: 'Big C',
    thaiPost: 'Thai Post',
    channelNames: 'Channel Names',
    address: 'Address',
    contact: 'Contact',
    name: 'Name',
    call: 'Call',
    onGoogleMap: 'Navigate',
    selectAll: 'Select All',
    unSelectAll : 'Unselect All',
    availableServices: 'Available Services :',
    showMore: 'Show more',
    showAll: 'Show more',
    collapse : 'Show less',
    search : 'Search area',
    searchBy : 'Search by',
    suggestions: 'Suggestions',
    loading : 'Loading . . .',
    noSuggestion:'No suggestions found',
  },
  th: {
    channel: 'ช่องทาง',
    channels: 'ช่องทาง',
    service: 'บริการ',
    services: 'บริการ',
    internationalTradeCenter: 'ศูนย์บริการธุรกิจต่างประเทศ',
    backAgent: 'BANK AGENT',
    branch: 'สาขา',
    atmCdm: 'ATM/CDM',
    theWisdom: 'THE WISDOM',
    foreignExchangeBooth: 'สำนักงานแลกเปลี่ยนเงินตราต่างประเทศ',
    smeBusinessCenter: 'ศูนย์ธุรกิจ SME',
    withdrawal: 'ถอนเงิน',
    verification: 'ตรวจสอบตัวตน',
    foreignExchange: 'แลกเงิน',
    smeServices: 'ศูนย์ SME',
    kCheckIdKiosks: 'K-Check ID Kiosks',
    bankingAgents: 'ตัวแทนธนาคาร',
    pttAmazon: 'PTT Amazon',
    kerry: 'Kerry',
    bigC: 'Big C',
    thaiPost: 'Thai Post',
    channelNames: 'Channel Names',
    address: 'ที่อยู่',
    contact: 'ติดต่อ',
    name: 'ชื่อ',
    call: 'โทรออก',
    onGoogleMap: 'นำทาง',
    selectAll: 'เลือกทั้งหมด',
    unSelectAll:'นำออกทั้งหมด',
    availableServices: 'บริการที่รองรับ :',
    showMore: 'ดูเพิ่มเติม',
    showAll : 'ดูเพิ่มเติม',
    collapse: 'ดูน้อยลง',
    search : 'ค้นหาที่นี่',
    searchBy : 'ค้นหาโดย',
    suggestions: 'แนะนำการค้นหา',
    loading : 'กำลังค้นหา . . .',
    noSuggestion: 'ไม่พบข้อมูล'
  },
} as { [key: string]: any };

const VALID_LANGS = ['th', 'en'];

const LangContextProvider: React.FC<LangContextProviderProps> = (
  props,
) => {
  const [lang, setLang] = useState(
    DEFAULT_LANG,
  );

  // use useLayoutEffect instead of useEffect because want to run before initial render
  useLayoutEffect(() => {
    // const selectedLang = window.localStorage.getItem('lang');
    const selectedLang = lang;
    switchLang(selectedLang);
  }, [lang]);

  const switchLang = (ln: string | null) => {
    let lnToUse = ln;
    if (!ln || ln === 'undefined') {
      lnToUse = DEFAULT_LANG;
    } else if (!VALID_LANGS.includes(ln)) {
      throw Error('invalid lang');
    }
    const lnStr = `${lnToUse}`;
    setLang(lnStr);
    // window.localStorage.setItem('lang', lnStr);
  };

  return (
    <LangContext.Provider
      value={{
        lang,
        switchLang,
        currentLangData: langData[lang],
      }}
    >
      {props.children}
    </LangContext.Provider>
  );
};

export default LangContextProvider;
