/*global google*/
import React, {
  useRef,
  useEffect,
  useImperativeHandle,
  forwardRef,
  useContext,
  useState,
  useCallback,
  useMemo,
} from 'react';

import {
  GoogleMap,
  LoadScript,
  Marker,
  DirectionsService,
  DirectionsRenderer,
} from '@react-google-maps/api'; // https://react-google-maps-api-docs.netlify.app/#section-introduction
import { StandaloneSearchBox } from '@react-google-maps/api';
import ScriptLoaded from "../../node_modules/@react-google-maps/api/src/docs/ScriptLoaded"
import { useUpdateEffect } from 'react-use';
import styled from 'styled-components/macro';

import { MapFilterDataContext } from '../contexts/MapFilterDataContext';
import { MapInfoContext } from '../contexts/MapInfoContext';
import { MultiDrawerStateContext } from '../contexts/MultiDrawerStateContext';
import useCurrentLocation, {
  DEFAULT_COORDS,
} from '../custom-hooks/useCurrentLocation';
import bluePoint from '../images/circle.png';
import mapImage from '../images/map-screenshot.png';
import GreenPin from '../images/pin.png';
import Chevron from '../images/arrow.png'
import Search from '../images/search.png'
import { green } from '@material-ui/core/colors';
import MapHelper from '../helpers/MapHelper';
import { useDebouncedCallback } from 'use-debounce';
import { translateScreenCenterDown } from '../contexts/CurrentLocationContext';
import { InputFocusContext } from '../contexts/InputFocusContext';

const WAIT_TIME_BEFORE_FETCH = 500;
var searchBox: any
const onLoaded = (ref: any) => searchBox = ref;
const onPlacesChanged = () => console.debug('main map onPlacesChanged', searchBox.getPlaces())
// console.log('sadasd',searchBox.getPlaces()[0].geometry.location.toJSON())
interface MainMapProps {
  ref: any;
  currentCoords?: {
    lat: number;
    lng: number;
  };
  zoom?: number;
  useFake?: boolean; // if true will display image instead (so we dont waste the map api call)
  withoutDebouncedFetchDataPoints?: () => void;
  debouncedFetchDataPoints?: () => void
}

const defaultProps = {
  currentCoords: DEFAULT_COORDS,
  zoom: 15,
};

const containerStyle = {
  width: '100%',
  height: '100%',
};


const MAP_LIBRARIES = ['geometry', 'places'];

const MainMapFunction = (
  {
    currentCoords = defaultProps.currentCoords,
    zoom = defaultProps.zoom,
    useFake = false,
    debouncedFetchDataPoints = () => { },
    withoutDebouncedFetchDataPoints = () => { }
  },
  ref: any, //TODO: come back to fix this

) => {
  const [map, setMap] = React.useState<null | any>(null);
  const [chance, setChance] = useState(1);
  const [zoomText, setZoomText] = useState(zoom);
  const [lastFetchedBounds, setLastFetchedBounds] = React.useState<
    null | any
  >(null);
  const {
    collapseAllDrawer,
    handleSetDrawerState,
    collapseDrawer,
  } = useContext(MultiDrawerStateContext);
  const {
    viewingPoint,
    directionResponse,
    handleSetViewingPoint,
    handleSetDirectionResponse,
    isShowingDirection,
  } = useContext(MapInfoContext);
  const { isFocus: isInputFocus } = useContext(InputFocusContext)
  const mainMapRef = useRef(null);


  useImperativeHandle(ref, () => {
    return {
      setCenter: setCenterAndFetchData,
      getCenter: getMapCenter, // TODO: consider chanign the getMapCenter's name to getCenter
    };
  });

  // fetch menusections
  const { selectedChoice, dataPoints, fetchDataPoints, keyword } = useContext(
    MapFilterDataContext,
  );

  const [randomDataPoints, setRandomDataPoints] = useState<any[]>([]);

  /**
   * figure out what datapoints to render after datapoints are updated
   */
  useUpdateEffect(() => {
    console.debug('dataPoints is updated');
    if (map) setLastFetchedBounds(map.getBounds());
    randomAndSetRandomDataPoints();
  }, [dataPoints]);

  /**
   * dataPoints + viewingPoint (if viewingPoint is not in dataPoints)
   */
  const toShowDataPoints = useMemo(() => {
    if (!viewingPoint) return randomDataPoints;
    const dataPointsIds = randomDataPoints.map((dp: any) => dp.id);
    let toReturn = randomDataPoints;
    if (!dataPointsIds.includes(viewingPoint.id)) {
      toReturn = toReturn.concat(viewingPoint);
    }
    return toReturn;
  }, [randomDataPoints]);


  /**
   * everytime the random datapoints is updated. change the center of the screen to be of the first point in the datapoints (already sorted by distance)
   */
  useUpdateEffect(() => {
    if (dataPoints && dataPoints.length && map) {
      map.setCenter({ lat: dataPoints[0].lat, lng: dataPoints[0].lng })
    }
  }, [randomDataPoints])




  /**
   * IMPORTANT
   * for first release, we do not random
   * but after 1st release will consider random
   */
  const randomAndSetRandomDataPoints = () => {
    if (!map) return;
    // when want to random, just change from getPointsToShow to getRandomPointsToShow
    const [toReturn, chance] = MapHelper.getPointsToShow(
      map,
      dataPoints,
    );
    setChance(chance);
    setRandomDataPoints(toReturn);
  };

  const onLoad = React.useCallback((map: any) => {
    console.debug('Map onLoad');
    setMap(map);
  }, []);

  const onUnmount = React.useCallback((map: any) => {
    setMap(null);
  }, []);

  // const handleCenterChanged = () => {
  //   if (map) {
  //     console.debug('center', map && map.center.lat(), map && map.center.lng());
  //   }
  // };

  const checkIfShouldFetch = (): boolean => {
    if (!map) return false;
    const curBounds = map.getBounds();
    const lastCenter = lastFetchedBounds.getCenter();
    const curCenter = curBounds.getCenter();
    const lastLat = lastCenter.lat();
    const lastLng = lastCenter.lng();
    const curLat = curCenter.lat();
    const curLng = curCenter.lng();
    const testDistance = google.maps.geometry.spherical.computeDistanceBetween(
      new google.maps.LatLng(lastLat, lastLng),
      new google.maps.LatLng(curLat, curLng),
    );
    console.debug(
      'checkIfShouldFetchAndFetch',
      ' old center ',
      lastLat,
      lastLng,
      ' new center ',
      curLat,
      curLng,
      ' distance between old and new centers',
      testDistance,
    );
    // TODO: check if new center is way off from the last center -> fetchData
    // check if outside of last fetchBound -> fetch data
    if (testDistance > 3000) {
      return true;
    } else {
      console.warn('try go further to get it load');
      return false;
    }
  };

  // Debounce callback
  const [debouncedCallbackBoundsChange] = useDebouncedCallback(
    // function
    () => {
      if (checkIfShouldFetch()) {
        callFetchDataPoint(true);
      } else {
        randomAndSetRandomDataPoints();
      }
    },
    // delay in ms
    WAIT_TIME_BEFORE_FETCH,
  );
  const handleBoundsChanged = () => {
    if (!map) return;
    if (isShowingDirection) return;
    if (!lastFetchedBounds) return;
    debouncedCallbackBoundsChange();
  };

  const handleZoomChanged = () => {
    if (map) {
      setZoomText(map.getZoom());
    }
  };

  const renderCenterMaker = () => {
    // has to wait till finish onLoad (map wont be null). ow will get google is not defined
    if (!map) return null;

    return (
      <Marker
        position={currentCoords}
        icon={{
          url: bluePoint,
          scaledSize: new google.maps.Size(28, 28), // scaled size
        }}
      ></Marker>
    );
  };
  const getMapCenter = () => {
    if (!map) return currentCoords;
    else
      return {
        lat: map.center.lat(),
        lng: map.center.lng(),
      };
  };

  const setCenterAndFetchData = (coords: { lat: number; lng: number }) => {
    if (map) {
      map.setCenter(coords);
      callFetchDataPoint(true);
    }
  };

  const isDataPointTheViewingPoint = (dataPoint: any) => {
    return viewingPoint && dataPoint.id === viewingPoint.id;
  };

  /**
   * TODO: define dataPoint interface in useFetchDatPoints and import it to use here
   * @param dataPoint
   */
  const getPinIconForDataPoint = (dataPoint: any) => {
    if (isDataPointTheViewingPoint(dataPoint)) {
      return {
        url: GreenPin,
        scaledSize: new google.maps.Size(40, 40), // scaled size
      };
    } else {
      return (
        dataPoint.pinIcon && {
          url: dataPoint.pinIcon,
          // if has viewingPoint then scale down
          scaledSize: viewingPoint
            ? new google.maps.Size(26, 37)
            : new google.maps.Size(26, 37), // scaled size 68 98
          // anchor: new google.maps.Point(5, 58),
        }
      );
    }
  };

  const getOpacityForDataPoint = (dataPoint: any) => {
    if (!viewingPoint) return 1;
    else {
      return isDataPointTheViewingPoint(dataPoint) ? 1 : 0.8;
    }
  };

  const renderMarkers = () => {
    // has to wait till finish onLoad (map wont be null). ow will get google is not defined
    // do not show all markers when showing direction
    if (!map || isShowingDirection) return null;

    return toShowDataPoints.map((dp: { [key: string]: any }) => {
      return (
        <Marker
          key={`${dp.id}-${dp.lat}-${dp.lng}`}
          position={new google.maps.LatLng(dp.lat, dp.lng)}
          icon={getPinIconForDataPoint(dp)}
          onClick={async () => {
            handleSetViewingPoint(dp);
            handleSetDrawerState('InfoPanel', 'NORMAL');
          }}
          opacity={getOpacityForDataPoint(dp)}
        />
      );
    });
  };

  const callFetchDataPoint = (immediately = false) => {
    // if repeatedly drag, only fires fetching at the last drag (stopped more than 500 ms)
    if (immediately) {
      withoutDebouncedFetchDataPoints();
    } else {
      debouncedFetchDataPoints();
    }
  };
  const renderDirection = () => {
    return (
      isShowingDirection &&
      viewingPoint &&
      viewingPoint.lat != null &&
      viewingPoint.lng != null && (
        <DirectionsService
          options={{
            destination: new google.maps.LatLng(
              viewingPoint.lat,
              viewingPoint.lng,
            ),
            origin: new google.maps.LatLng(
              currentCoords.lat,
              currentCoords.lng,
            ),
            travelMode: google.maps.TravelMode.DRIVING,
          }}
          callback={handleSetDirectionResponse}
          onLoad={(directionsService) => {
            console.debug(
              'DirectionsService onLoad directionsService: ',
              directionsService,
            );
          }}
          // optional
          onUnmount={(directionsService) => {
            console.debug(
              'DirectionsService onUnmount directionsService: ',
              directionsService,
            );
          }}
        />
      )
    );
  };
  const renderDirectionMarker = () => {
    return (
      isShowingDirection &&
      viewingPoint && (
        <Marker
          position={
            new google.maps.LatLng(viewingPoint.lat, viewingPoint.lng)
          }
          icon={{
            url: GreenPin,
            scaledSize: new google.maps.Size(45, 45), // scaled size
          }}
        />
      )
    );
  };
  const renderDirectionResponse = () => {
    return (
      directionResponse !== null && (
        <DirectionsRenderer
          // https://developers.google.com/maps/documentation/javascript/reference#DirectionsRendererOptions
          options={{
            suppressMarkers: true,
            directions: directionResponse,
            preserveViewport: true, // prevent snap back to this spot
            polylineOptions: {
              strokeColor: '#45C2B1',
              strokeOpacity: 0.8,
              strokeWeight: 5,
              clickable: false,
              draggable: false,
              editable: false,
              visible: true,
            },
          }}
        />
      )
    );
  };

  const [isCollapsed, setIsCollapsed] = useState(true);
  const handleToggleCollaps = () => {
    setIsCollapsed(!isCollapsed);
  };
  const center = useMemo(() => {
    return translateScreenCenterDown(currentCoords)
  }, [currentCoords])
  return (
    <SMainMap ref={mainMapRef}>
      {/* after first release, will show chance */}
      {/* <SChance>{chance < 1 ? chance.toFixed(3) : chance}</SChance> */}
      <SZoomText className={isInputFocus ? 'hide' : ''}>{`${zoomText}z`}</SZoomText>
      {useFake ? (
        <FakeImage src={mapImage} />
      ) : (
          <LoadScript
            googleMapsApiKey={process.env.REACT_APP_MAP_API_KEY}
            libraries={MAP_LIBRARIES}
          >

            <GoogleMap
              mapContainerStyle={containerStyle}
              center={center}
              zoom={zoom}
              onLoad={onLoad}
              onUnmount={onUnmount}
              onClick={() => {
                if (viewingPoint) {
                  handleSetViewingPoint(null);
                  collapseDrawer('InfoPanel');
                  handleSetDrawerState('MainFilterPanel', 'NORMAL');
                } else collapseAllDrawer();
              }}
              options={{
                mapTypeControl: false, // to not show satellite map control option
                fullscreenControl: false, // to not show fulscreen control option
                disableDefaultUI: true, // to not show anything
                styles:[
                  {
                    featureType: "administrative.land_parcel",
                    elementType: "labels",
                    stylers: [
                      {
                        visibility: "off"
                      }
                    ]
                  },
                  {
                    featureType: "administrative.neighborhood",
                    stylers: [
                      {
                        visibility: "off"
                      }
                    ]
                  },
                  {
                    featureType: "poi",
                    stylers: [
                      {
                        visibility: "off"
                      }
                    ]
                  },
                  {
                    featureType: "poi.business",
                    stylers: [
                      {
                        visibility: "on"
                      }
                    ]
                  },
                  {
                    featureType: "poi.park",
                    elementType: "labels.text",
                    stylers: [
                      {
                        visibility: "off"
                      }
                    ]
                  },
                ]
              }}
              onBoundsChanged={handleBoundsChanged}
              onZoomChanged={handleZoomChanged}
            // onCenterChanged={handleCenterChanged}
            >
              {/* TODO: might remove this since is not used anymore */}
              {/* <SSearchWrapper>
                {renderSearchBar()}
              </SSearchWrapper> */}
              {renderDirection()}
              {renderMarkers()}
              {renderDirectionMarker()}
              {renderDirectionResponse()}
              {renderCenterMaker()}
            </GoogleMap>
          </LoadScript>
        )}
    </SMainMap>
  );
};

const MainMapWrapper: React.FC<MainMapProps> = React.forwardRef(
  MainMapFunction,
);
export default MainMapWrapper;
const SSearchWrapper = styled.div`
  display:flex;
  margin: 10px 5px;
  background-color:white;
  position: absolute;
  right: 0;
`;
const SToggler = styled.div`
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.colors.palette.primary};
  width: 40px;
  // border-right: solid 1px ${(props) =>
    props.theme.colors.grey.light};

  justify-content: center;
`;

const SCloseIcon = styled.img`
width:10vw;
transform: rotate(90deg);
margin-top:-0.5vh;
@media screen and (min-width: 768px) {
  width:7vw;
}
`
const SOpenIcon = styled.img`
width:10vw;
transform: rotate(270deg);
margin-top:-0.5vh;
@media screen and (min-width: 768px) {
  width:7vw;
}
`
const SInputWrapper = styled.div`
`;

const SSearchBar = styled.div.attrs((props) => ({
  className: props.className,
}))`
  display: flex;
  border-radius: 5px;
  justify-content: flex-end;
  box-shadow: rgba(0, 0, 0, 0.18) 1px 1px 2px 0px;
  &.collapsed {
    & ${SInputWrapper} {
      width: 0px;
      overflow: hidden;
    }
  }
  & ${SInputWrapper} {
    width: calc(102vw - 60px);
    @media screen and (min-width: 768px) {
      width: calc(101vw - 60px);
    }
  }
`;
const SZoomText = styled.div.attrs(props => ({
  className: props.className
}))`
  position: absolute;
  font-size: 0.5rem;
  // font-weight: 600;
  top: 0;
  right: 0;
  z-index: 10000;
  margin: 0.3rem;
  &.hide {
    z-index: 0;
  }
`;
const SSearchBox = styled.input`
  margin-top:0.5vh;
  width: 74vw;
  height: 4vh;
  border:none;

  @media screen and (min-width: 768px) {
    font-size:2vh;
    width:77vw;
  }
`
const SChance = styled.div`
  position: absolute;
  font-size: 0.5rem;
  font-weight: 600;
  top: 0;
  right: 0;
  z-index: 10000;
  margin: 0.3rem;
`;

const FakeImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
const SMainMap = styled.div`
  // Important! Always set the container height explicitly for map
  height: 100vh;
  width: 100%;
`;

const circleoptions = {
  strokeColor: 'rgb(3, 169, 244)',
  strokeOpacity: 1,
  strokeWeight: 1,
  fillColor: 'rgb(3, 169, 244)',
  fillOpacity: 1,
  clickable: false,
  draggable: false,
  editable: false,
  visible: true,
  radius: 30000,
  zIndex: 15,
};
