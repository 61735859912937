const common = {
  fontFamilies: {
    body: `Roboto, sans-serif`,
    title: `Prompt, sans-serif`,
  },
  fontSizes: {
    extraSmall: '12px',
    small: '14px',
    medium: '20px',
    big: '30px',
  },
  lineHeights: {
    classic: 1.58,
  },
};

export default common;
