import React, { useState, useContext } from 'react';

import styled from 'styled-components/macro';

import { MapInfoContext } from '../contexts/MapInfoContext';
import BottomDrawer from './BottomDrawer';
import DirectionBar from './DirectionBar';
import { LangContext } from '../contexts/LangContext';
import { MultiDrawerStateContext } from '../contexts/MultiDrawerStateContext';

export const infoPanelMoreHeight = '52vh';

const formatAddressAndOfficeHourText = (
  address: string,
  officeHour: string,
) => {
  let total = '';
  if (address) total += address;
  if (officeHour) total += ` ${officeHour}`;
  return total;
};

const InfoPanel = () => {
  const { drawerStateMap } = useContext(MultiDrawerStateContext);
  const { viewingPoint } = useContext(MapInfoContext);
  const { currentLangData } = useContext(LangContext);
  console.debug('viewingPoint', viewingPoint);
  if (!viewingPoint) return null;

  const getRowsForViewingPoint = () => {
    return [
      {
        label: `${currentLangData.channel}`,
        value: viewingPoint.filterLabel, // @Sarun TODO: check with March which field he returned this info (could be agent_name)
      },
      {
        label: `${currentLangData.name}`,
        value: viewingPoint.name,
      },
      {
        label: `${currentLangData.address}`,
        value: formatAddressAndOfficeHourText(
          viewingPoint.address,
          viewingPoint.officeHour,
        ),
      },
      {
        label: `${currentLangData.contact}`,
        value: viewingPoint.contact || '-',
      },
    ];
  };
  return (
    <BottomDrawer
      drawerName="InfoPanel"
      heights={{ more: infoPanelMoreHeight }}
    >
      <SData>
        <SServices>
          <SServiceHeader>
            {currentLangData.availableServices}
          </SServiceHeader>
          <SServicesLogos>
            {viewingPoint.services.map(
              (service: { name: string; logo: any }) => {
                return (
                  <SLogo key={service.name} src={service.logo} />
                );
              },
            )}
          </SServicesLogos>
        </SServices>
        <STable>
          {/* @Sarun TODO: change label to vary by language. use useContext(LangContext) */}
          <tbody>
            {getRowsForViewingPoint().map((item: any) => (
              <tr key={item.label}>
                <STableLable>{item.label} : </STableLable>
                <td>{item.value}</td>
              </tr>
            ))}
          </tbody>
        </STable>
      </SData>

      {/*
        if collapsed, do not show DirectionBar
        TODO: considering using display: None and class
       */}
      {drawerStateMap.InfoPanel != "COLLAPSED" && <DirectionBar />}
    </BottomDrawer>
  );
};

export default InfoPanel;


const SServiceHeader = styled.div`
  font-family: ${(props) => props.theme.fontFamilies.title};
  font-weight: ${(props) => props.theme.fontWeights.title};
  font-size: 1rem;
  margin-bottom: 0.5rem;
`

const SServices = styled.div`

`

const SServicesLogos = styled.div``


const SData = styled.div`
  font-family: ${(props) => props.theme.fontFamilies.body};
  font-style: normal;
  font-weight: 500;
  font-size: ${(props) => props.theme.fontSizes.small};
  color: #4d4d4d;
  padding: 0 7vw;
  // margin-top:3vh;
`;


const STableLable = styled.td`
  vertical-align: top;
  font-weight: 600;
  width: 18vw;
`;


const STable = styled.table`
  width: 100%;
  line-height: 3.3vh;
`;

const SLogo = styled.img`
  height: 35px;
  margin-right: 2vw;
  margin-top:-2vw;
  box-shadow: 0px 0.5px 3px rgba(0, 0, 0, 0.25);
  border : none;
  border-radius: 30px;
  @media screen and (min-width: 768px) {
    margin-top:0.5vw
  }
`;
