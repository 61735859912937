import React, {
  useContext,
  useState,
  useReducer,
  useMemo,
  useEffect,
  useCallback,
} from 'react';

// images
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import { green } from '@material-ui/core/colors';
import Icon from '@material-ui/core/Icon';
import CheckIcon from '@material-ui/icons/Check';
import CheckBox from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlank from '@material-ui/icons/CheckBoxOutlineBlank';
import { useUpdateEffect } from 'react-use';
import styled from 'styled-components/macro';

import ToggleableButton from '../components/ToggleableButton';
import { DrawerStateContext } from '../contexts/DrawerStateContext';
import { FilterContext } from '../contexts/FilterContext';
import { LangContext } from '../contexts/LangContext';
import { LoadingContext } from '../contexts/LoadingContext';
import { MapFilterDataContext } from '../contexts/MapFilterDataContext';
import { MultiDrawerStateContext } from '../contexts/MultiDrawerStateContext';
import getColorByIndex from '../helpers/getColorByIndex';
import BottomDrawer from './BottomDrawer';
import FilterModel from '../models/FilterModel';


interface MainFilterPanelProps { }

const MainFilterPanel: React.FC<MainFilterPanelProps> = () => {
  const drawerStateKey = 'MainFilterPanel';
  const { drawerStateMap, handleSetDrawerState } = useContext(
    MultiDrawerStateContext,
  );

  const drawerState = useMemo(() => drawerStateMap[drawerStateKey], [
    drawerStateMap,
  ]);

  const { currentLangData } = useContext(LangContext);

  const { filterModel, fetchAllMenuSections } = useContext(
    FilterContext,
  );

  const { isAnyServiceLoading } = useContext(LoadingContext);
  const disabledClick = isAnyServiceLoading;

  const {
    selectedChoice,
    dispatchSelectedChoice,
  } = useContext(MapFilterDataContext);

  /**
   * on init if still loading then just stay collapsed
   */
  useEffect(() => {
    fetchMenuAndSetDrawerWhenDone();
  }, []);

  const fetchMenuAndSetDrawerWhenDone = useCallback(async () => {
    // start wil collapsed
    handleSetDrawerState(drawerStateKey, 'COLLAPSED');
    await fetchAllMenuSections();
    if (filterModel) handleSetDrawerState(drawerStateKey, 'NORMAL');
  }, []);



  // TODO: consider using onEffect for select parent of bankingAgent[]

  const [selectAllOfSection, setSelectAllOfSection] = useState({
    services: false,
    channels: false,
    bankingAgents: false,
  } as { [key: string]: any });

  // TODO: add method to check for a given section if is selected all -> highlight button / determine text

  const renderAvartar = (
    logoUrl: string,
    wholeLabel: string,
    index: number,
    isSelected: boolean,
  ) => {
    if (logoUrl) {
      return (
        <SAvatar
          className={isSelected ? 'is-selected' : ''}
          src={logoUrl}
        />
      );
    } else {
      return (
        <SAvatar
          style={{ background: getColorByIndex(index) }}
          className={isSelected ? 'is-selected' : ''}
        >
          {wholeLabel && wholeLabel[0]}
        </SAvatar>
      );
    }
  };

  // TODO: considering create our own chip instead of using material ui
  const renderChipButtons = (choices: Array<any>) => {
    return (
      <SChipButtons>
        {choices.map((choice: any, index: number) => {
          let avatar;
          if (choice.buttonLogoUrl) {
            avatar = <Avatar src={choice.buttonLogoUrl} />;
          } else {
            avatar = <Avatar>{choice.label[0]}</Avatar>;
          }
          const thisLabel = choice.label;
          const isSelected = isChoiceSelected(choice);
          return (
            <SToggleButtonWrapper key={thisLabel}>
              <ToggleableButton
                isSelected={isSelected}
                imageSrc={choice.buttonLogoUrl}
                label={thisLabel}
                onToggle={() =>
                  handleToggleFilter(choice, isSelected)
                }
                disabled={disabledClick}
              />
            </SToggleButtonWrapper>
          );
        })}
      </SChipButtons>
    );
  };

  const autoToggleMore = () => {
    if (drawerState !== 'MORE') {
      handleSetDrawerState(drawerStateKey, 'MORE');
    }
  };
  // use reducers
  const handleToggleFilter = (
    choice: { [key: string]: any },
    wasSelected: boolean, //TODO: amybe considering remove the wasSelected
  ) => {
    console.debug('choice', choice);
    dispatchSelectedChoice({
      type: wasSelected ? 'UNSELECT' : 'SELECT',
      payload: {
        name: choice.filterName,
        kind: choice.filterType,
        filterModel,
        autoToggleMore,
        setSelectAllOfSectionNames,
      },
    });
  };

  const handleToggleSectionSelectAll = (sectionName: string) => {
    const wasSelected = selectAllOfSection[sectionName];
    setSelectAllOfSection({
      ...selectAllOfSection,
      [sectionName]: !wasSelected,
    });
    if (wasSelected) {
      // then now unselect
      dispatchSelectedChoice({
        type: 'UNSELECT_ALL',
        payload: {
          kind: FilterModel.getKindForSectionNames(sectionName),
          filterModel,
          setSelectAllOfSectionNames, // for when unselect all channels (includes banking agents) will auto unselect all banking agents
        },
      });
    } else {
      // then now select
      dispatchSelectedChoice({
        type: 'SELECT_ALL',
        payload: {
          kind: FilterModel.getKindForSectionNames(sectionName),
          autoToggleMore,
          filterModel, // for figuring out chlidren choices
          setSelectAllOfSectionNames, // for when select all channels (includes banking agents) will autoselect all banking agents
        },
      });
    }
  };

  const isChoiceSelected = (choice: any) => {
    // todo edit this
    const { filterType, filterName } = choice;
    return selectedChoice[filterType].includes(filterName);
  };

  const handleToggleMore = () => {
    const newState = drawerState === 'MORE' ? 'NORMAL' : 'MORE';
    handleSetDrawerState(drawerStateKey, newState);
  };

  const menuSectionsToDisplay = useMemo(() => {
    if (drawerState === 'MORE') {
      return filterModel?.allMenuSections;
    } else {
      return filterModel?.lessMenuSections;
    }
  }, [filterModel, drawerState]);

  const setSelectAllOfSectionNames = (
    sectionNames: Array<string>,
    value: boolean,
  ) => {
    const nameValue = sectionNames.reduce(
      (acc: any, name: string) => ({ ...acc, [name]: value }),
      {},
    );
    setSelectAllOfSection({
      ...selectAllOfSection,
      ...nameValue,
    });
  };

  const shouldRenderChipButtons = (sectionName: string) => {
    return sectionName === 'bankingAgents';
  };

  const renderMainButtons = (choices: Array<any>) => {
    return choices.map(
      (choice: { [key: string]: any }, index: number) => {
        const thisLabel = choice.label;
        const isSelected = isChoiceSelected(choice);
        return (
          <SButtonWrapper
            className={disabledClick ? 'disabled' : ''}
            key={thisLabel}
            onClick={() =>
              disabledClick
                ? null
                : handleToggleFilter(choice, isSelected)
            }
          >
            {renderAvartar(
              choice.buttonLogoUrl,
              thisLabel,
              index - 1,
              isSelected,
            )}
            <SButtonLabel className={isSelected ? 'is-selected' : ''}>
              {thisLabel}
            </SButtonLabel>
            {isSelected && <SCheckIcon />}
          </SButtonWrapper>
        );
      },
    );
  };

  return (
    <BottomDrawer drawerName="MainFilterPanel">
      {(menuSectionsToDisplay || []).map(
        (section: { [key: string]: any }, indexSection: number) => {
          const isSelectedAll = selectAllOfSection[section.name];
          return (
            <SSectionWrapper key={section.name}>
              <SSectionTitleWrapper>
                <SSectionTitle>
                  {currentLangData[section.name]}

                </SSectionTitle>
                {/* MORE BUTTON (might move) */}

                {/* {indexSection === 0 && (
                <SFirstSectionRightWrapper>
                  <SRoundButton onClick={handleToggleMore}>
                    {drawerState === 'MORE' ? 'COLLAPSED' : 'More'}
                  </SRoundButton>
                </SFirstSectionRightWrapper>
              )} */}
              {console.log('safsaf',currentLangData[section.name])}
                {currentLangData[section.name] == 'bankingAgents' || currentLangData[section.name] == 'Banking Agents'|| currentLangData[section.name] == 'ตัวแทนธนาคาร' && (
                  <SRoundButton
                    onClick={() =>
                      disabledClick
                        ? null
                        : handleToggleSectionSelectAll(section.name)
                    }
                    className={`${isSelectedAll ? 'is-selected' : ''} ${
                      disabledClick ? 'disabled' : ''
                      }`}
                  >
                    <SSelectAllText>
                      {isSelectedAll === false && (
                        currentLangData.selectAll
                      )}
                      {isSelectedAll === true && (
                        currentLangData.unSelectAll
                      )}
                    </SSelectAllText>
                  </SRoundButton>
                )}

              </SSectionTitleWrapper>

              {/* TODO: if more is MORE should't be scrollable. should just be wrapped */}
              <SScrollableContainer
                className={drawerState && drawerState.toLowerCase()}
              >
                {shouldRenderChipButtons(section.name)
                  ? renderChipButtons(section.choices)
                  : renderMainButtons(section.choices)}
              </SScrollableContainer>
            </SSectionWrapper>
          );
        },
      )}
      {/* TODO: might remove || 1.2.2 and use LOCAL */}
      <SVersion>version : {process.env.REACT_APP_VERSION || '1.2.2'}</SVersion>
    </BottomDrawer>
  );
};

export default MainFilterPanel;

const SVersion = styled.div`

  color: #d3d3d3;
  font-size: 2vw;
  position: absolute;
  right: 1vw;
`;

const SToggleButtonWrapper = styled.div`

  margin-bottom: 5px;
`;
const SFirstSectionRightWrapper = styled.div`
  display: flex;
  margin-right: 10px;
`;
const SSectionSelectAllWrapper = styled.div`
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 9px;
`;
const SSelectAllText = styled.div`

`;

const SChipButtons = styled.div`

  margin-left: 15px;
  display: flex;
  flex-wrap: wrap;
`;

const SRoundButton = styled.div`

  border-radius: 1rem;
  font-size: 11px;
  border: solid 1px ${(props) => props.theme.colors.text.grey};
  // box-shadow: rgba(0, 0, 0, 0.18) 1px 1px 1px 0px;
  min-width: 90px;
  height: 1.2rem;
  margin-right: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:active {
    box-shadow: rgba(0, 0, 0, 0.18) 1px 1px 2px 0px;
    // transition: box-shadow 0.2s;
  }
  &.is-selected {
    background: ${(props) => props.theme.colors.palette.primary};
    color: ${(props) => props.theme.colors.common.white};
  }
  &.disabled {
    opacity: ${(props) => props.theme.disabled.opacity};
  }
`;

const SAvatar = styled(Avatar)`

  &.MuiAvatar-root {
    min-width: 35px;
    width: 10vw;
    height: 10vw;
    // min-height: 35px;
    font-size: 4vw;
  }
  margin-bottom: 0.2em;
  margin-left: auto;
  margin-right: auto;

  &.is-selected {
    box-shadow: rgba(0, 0, 0, 0.18) 1px 1px 2px 1px;
  }
`;

const SCheckIcon = styled(CheckIcon)`

  color: ${(props) => props.theme.colors.common.white};
  &.MuiSvgIcon-root {
    font-size: ${(props) => props.theme.fontSizes.small};
    box-shadow: rgba(0; 0, 0, 0.18) 1px 0px 0px 1px;
  }
  background: ${(props) => props.theme.colors.palette.primary};
  border-radius: 50%;
  padding: 1vw;
  position: absolute;
  bottom: 1.6rem;
  right: 2vw;

`;

const SSectionWrapper = styled.div`

`;

const SButtonLabel = styled.div.attrs((props) => ({
  className: props.className,
}))`

  font-size: ${(props) => props.theme.fontSizes.small};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 5px;

  // TODO: consider do something with the font
  &.is-selected {
  }
`;

const SSectionTitleWrapper = styled.div`

  display: flex;
  justify-content: space-between;
`;

const SSectionTitle = styled.div`

  padding: 0px 15px;
  font-family: ${(props) => props.theme.fontFamilies.title};
  font-weight: ${(props) => props.theme.fontWeights.title};
  color: ${(props) => props.theme.colors.text.primary};
  margin-bottom: 5px;
`;

const SButtonWrapper = styled.div.attrs((props) => ({
  className: props.className,
}))`
  transform: translate3d(0, 0, 0);
  text-align: center;
  display: inline-block;
  width: 18vw;
  min-width: 62px;
  position: relative;
  border-radius: 50%;
  height: 50%;
  &.disabled {
    opacity: ${(props) => props.theme.disabled.opacity};
  }
`;

const SScrollableContainer = styled.div.attrs((props) => ({
  classNames: props.className,
}))`
  overflow: auto;
  overflow-y: hidden;
  scrollbar-width: none;
  -ms-overflow-style: none;
  -ms-overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  white-space: nowrap;

  ::-webkit-scrollbar {
    display: none;
  }

  &.more {
    overflow: unset;
    white-space: unset;
  }

  & > ${SButtonWrapper} ~ ${SButtonWrapper} {
    margin-left: 1.2rem; // need this to see 4.5 icons
  }

  &.more > ${SButtonWrapper} {
    margin-left: 0.3rem; // need this to see 4.5 icons
  }

  // ipad
  @media screen and (min-width: 768px) {
    &.more > ${SButtonWrapper} {
      width: 19vw;
    }
    & > ${SButtonWrapper} {
      width: 20vw; // need this to see 4.5 icons
    }
  }
`;
