import React, { useContext, useState, useEffect } from 'react';
import { LangContext } from '../contexts/LangContext';
import { LoadingContext } from '../contexts/LoadingContext';
import { FilterContext } from '../contexts/FilterContext';
import kBankLogo from '../images/kbank-logo.png';
import initApiInstance from './initApiInstance';
import filtersData from '../mock/filtersData';

const useFetchPointDetail = () => {
  const { lang } = useContext(LangContext);
  const apiInstance = initApiInstance();
  const [pointDetail, setDataPointDetail] = useState<{
    [key: string]: any;
  } | null>(null);
  const { isAnyServiceLoading, handleSetServiceLoading } = useContext(
    LoadingContext,
  );
  const { filterModel } = useContext(FilterContext);

  useEffect(() => {
    handleSetServiceLoading('useFetchPointDetail', true);
  }, []);

  const addLogosToServices = (data: any) => {
    return {
      ...data,
      services: data.services.map((service: string) => {
        const logo =
          filterModel.getImageUrlOf(service, 'button') || kBankLogo;
        return {
          name: service,
          logo,
        };
      }),
    };
  };

  /**
   * remove this when new backend is done
   */
  const addFilterLabel = (data: any) => {
    return {
      ...data,
      filterLabel: filterModel.getLabelOfName(data.filterName),
    };
  };

  /**
   *
   * @param id
   * @param extraInfo //for mock only
   */
  const fetchData = async (id: number, extraInfo = {}) => {
    new Promise(async (resolve) => {
      // TODO: change this to call api instead
      // const data = getPointDetailById(id);
      // format data here. add the icon
      handleSetServiceLoading('useFetchPointDetail', true);
      console.debug('isAnyServiceLoading', isAnyServiceLoading);
      const data = await apiInstance
        .get(`locators/${id}?lang=${lang}`)
        .then((res) => {
          console.log(`id : `, res.data);
          return res.data;
        });
      let formatted = addLogosToServices(data);
      // if new backend is done then remove next line
      formatted = addFilterLabel(formatted);
      handleSetServiceLoading('useFetchPointDetail', false);
      const updatedData = { ...formatted, ...extraInfo };
      setDataPointDetail(updatedData);
      resolve(updatedData);
    });
  };

  const clearPointDetail = () => {
    setDataPointDetail(null);
  };

  return { pointDetail, fetchData, clearPointDetail };
};

export default useFetchPointDetail;
