import { DEFAULT_LANG } from '../contexts/LangContext';
import { FilterData } from '../mock/filtersData';

const langToLabelKey = {
  th: {
    short: 'shortLabelTh',
    long: 'labelTh',
  },
  en: {
    short: 'shortLabelEn',
    long: 'labelEn',
  },
} as { [key: string]: any };

/**
 * format the new data to be in the old format
 * filter type ex: service,
 */
const getGroupedRawDataBySectionNames = (
  oriRawData: null | { [key: string]: any },
): null | { [key: string]: any[] } => {
  const groupedRawData: { [key: string]: any } = {};
  if (!oriRawData) return null;
  oriRawData.forEach((data: any) => {
    if (data.filterType) {
      const ft = data.filterType;
      // if not already in
      if (!(ft in groupedRawData)) {
        groupedRawData[ft] = [];
      }
      groupedRawData[ft] = groupedRawData[ft].concat(data);
    }
  });

  const addSToKey = Object.keys(groupedRawData).reduce(
    (acc: any, filterType: string) => ({
      ...acc,
      [FilterModel.getSectionNameOfKind(filterType)]: groupedRawData[
        filterType
      ],
    }),
    {},
  );

  return addSToKey;
};

const getFilterLabelKeyForLang = (lang: string = DEFAULT_LANG) => {
  if (lang === 'en') return 'labelEn';
  else if (lang === 'th') return 'labelTh';
  return 'labelEn';
};

class FilterModel {
  oriRawData: { [key: string]: any } | null = null;
  rawData: { [key: string]: any[] } | null = null;
  lang = DEFAULT_LANG;
  nameLogosMap: {
    [key: string]: { pinLogoUrl: string; buttonLogoUrl: string };
  } = {};
  nameLabelMap: { [key: string]: string } = {};
  allMenuSections: {
    name: string;
    choices: { longLabel: any; label: any }[];
  }[] = [];

  sectionsNames = ['services', 'channels', 'bankingAgents'];

  constructor(
    oriRawData: { [key: string]: any } | null,
    lang: string,
  ) {
    this.oriRawData = oriRawData;
    this.rawData = getGroupedRawDataBySectionNames(oriRawData);
    this.lang = lang;
    this.allMenuSections = this.getAllMenuSections();
    this.nameLogosMap = this.getNameLogosMap();
    this.nameLabelMap = this.getNameLabelMap();
  }

  setLang(newLang: string) {
    this.lang = newLang;
  }

  formatLabelOfRawChoices(rawChoices: Array<{ [key: string]: any }>) {
    return rawChoices.map((s: { [key: string]: any }) => ({
      ...s,
      longLabel: s[langToLabelKey[this.lang]['long']] || '-',
      label: s[langToLabelKey[this.lang]['short']] || '-', // short
    }));
  }

  /**
   * TODO: change this to work with the new raw filter data format
   */
  getAllMenuSections() {
    return this.sectionsNames.map((name: string) => ({
      name,
      choices: this.rawData
        ? this.formatLabelOfRawChoices(this.rawData[name])
        : [],
    }));
  }

  /**
   * TODO: will not need kind any more
   * @param name  ex: withdrawal
   * @param kind
   */
  getImageUrlOf(name: string, imageKind: 'button' | 'pin') {
    const first: { [key: string]: string } = this.nameLogosMap[name];
    if (!first) return;
    return first[`${imageKind}LogoUrl`];
  }

  /**
   * TODO: remove the kind level. just do direct name to logo map
   */
  getNameLogosMap() {
    return this.oriRawData?.reduce(
      (acc: any, row: any) => ({
        ...acc,
        [row.filterName]: {
          pinLogoUrl: row.pinLogoUrl,
          buttonLogoUrl: row.buttonLogoUrl,
        },
      }),
      {},
    );
  }

  /**
   * long label
   */
  getLabelOfName(name: string) {
    return this.nameLabelMap[name];
  }

  /**
   * selected lang only
   */
  getNameLabelMap() {
    return this.oriRawData?.reduce(
      (acc: any, row: any) => ({
        ...acc,
        [row.filterName]: row[getFilterLabelKeyForLang(this.lang)],
      }),
      {},
    );
  }

  /**
   * no bankingAgents
   */
  get lessMenuSections() {
    return this.allMenuSections.filter(
      (section: { [key: string]: any }) =>
        section.name !== 'bankingAgents',
    );
  }

  get bankingAgentsNames() {
    return this.rawData
      ? this.rawData.bankingAgents.map((ba: any) => ba.filterName)
      : [];
  }

  getChoicesNamesOfSectionName(sectionName: string) {
    const section = this.allMenuSections.find(
      (sn: any) => sn.name === sectionName,
    );
    return section?.choices.map((c: any) => c.filterName) || [];
  }

  getChoicesLengthOfSectionName(sectionName: string) {
    return this.getChoicesNamesOfSectionName(sectionName).length;
  }

  static getKindForSectionNames(sectionName: string) {
    return sectionName.substring(0, sectionName.length - 1);
  }

  static getSectionNameOfKind(kind: string) {
    return `${kind}s`;
  }

  static formatSelectedChoiceToHaveSectionNamesAsKeys(selectedChoice: {
    [key: string]: string[];
  }) {
    return Object.keys(selectedChoice).reduce(
      (acc: any, kind: string) => ({
        ...acc,
        [FilterModel.getSectionNameOfKind(kind)]: selectedChoice[
          kind
        ],
      }),
      {},
    );
  }
}
export default FilterModel;
