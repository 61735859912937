import { useEffect, useState } from 'react';

/**
 * Vitory Monument location
 */
export const DEFAULT_COORDS = {
  lat: 13.7649084,
  lng: 100.5360959,
};
const useCurrentLocation = () => {
  const [coords, setCoords] = useState(DEFAULT_COORDS);

  useEffect(() => {
    reReadCurrentLocation();
  }, []);

  const reReadCurrentLocation = () => {
    if (navigator.geolocation) {
      /**
       * HACKY: add dummy read to help improve accuracy
       * https://stackoverflow.com/questions/3397585/navigator-geolocation-getcurrentposition-sometimes-works-sometimes-doesnt
       */
      navigator.geolocation.getCurrentPosition(
        () => { },
        () => { },
        {},
      );
      navigator.geolocation.getCurrentPosition(
        (pos: any) => {
          console.debug('REREAD location: navigator', pos);
          const latlng = {
            lat: pos.coords.latitude,
            lng: pos.coords.longitude,
          };
          setCoords(latlng);
        },
        (err) => {
          console.error(`REREAD location error`, err);

        },
        {
          maximumAge: 60000,
          timeout: 5000,
          enableHighAccuracy: true,
        },
      );
    }
  };

  // Return the width so we can use it in our components
  return { coords, reReadCurrentLocation };
};

export default useCurrentLocation;
