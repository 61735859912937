import React, {
  createContext,
  useState,
  useMemo,
  useCallback,
  useRef,
} from 'react';

import { getPointDetailById } from '../mock/pointDetail';
import useFetchPointDetail from '../services/useFetchPointDetail';

export const MapInfoContext = createContext<{ [key: string]: any }>({
  viewingPoint: null,
  directionResponse: null,
  handleSetViewingPoint: () => {},
  handleSetDirectionResponse: () => {},
  isShowingDirection: false,
  handleSetIsShowingDirection: () => {},
  handleCancelShowingDirection: () => {},
});
const MapInfoContextProvider = (props: any) => {
  const {
    pointDetail,
    fetchData: fetchPointDetail,
    clearPointDetail,
  } = useFetchPointDetail();
  const [directionResponse, setDirectionResponse] = useState(null);
  const dirRef = useRef(null);

  const [isShowingDirection, setIsShowingDirection] = useState(false);
  const handleSetViewingPoint = (dp: any | null) => {
    // TODO: change to use fetch data here
    if (dp) {
      fetchPointDetail(
        dp.id,
        // for mock only
        {
          lat: dp.lat,
          lng: dp.lng,
        },
      );
    } else {
      clearPointDetail();
      handleCancelShowingDirection();
    }
  };

  const setDirectionResponseAndRef = (res: any) => {
    setDirectionResponse(res);
    dirRef.current = res;
  };
  const handleSetDirectionResponse = useCallback((res: any) => {
    if (res && !dirRef.current) {
      if (res.status === 'OK') {
        setDirectionResponseAndRef(res);
      } else {
        console.error('Error loading direction response: ', res);
      }
    }
  }, []);

  const handleSetIsShowingDirection = (value: boolean) => {
    setIsShowingDirection(value);
  };

  const handleCancelShowingDirection = () => {
    handleSetIsShowingDirection(false);
    setDirectionResponseAndRef(null);
  };

  const viewingPoint = useMemo(() => pointDetail, [pointDetail]);
  return (
    <MapInfoContext.Provider
      value={{
        viewingPoint,
        handleSetViewingPoint,
        directionResponse,
        handleSetDirectionResponse,
        isShowingDirection,
        handleSetIsShowingDirection,
        handleCancelShowingDirection,
      }}
    >
      {props.children}
    </MapInfoContext.Provider>
  );
};

export default MapInfoContextProvider;
