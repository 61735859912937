import React from 'react';

import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';

import LangContextProvider from './contexts/LangContext';
import ThemeContextProvider from './contexts/theme/Theme';
import MainPage from './pages/MainPage';
import 'font-awesome/css/font-awesome.css';

const App = () => {
  return (
    <LangContextProvider>
      <ThemeContextProvider>
        <Router>
          <Switch>
            <Route exact path="/">
              <Redirect to="/th/app" />
            </Route>
            <Route exact path="/:lang" 
            render={props => (
              <Redirect to={`/${props.match.params.lang}/app`} />
            )}>
            </Route>
            <Route path="/:lang/:screenMode">
              <MainPage />
            </Route>
            {/* add other routes here */}
          </Switch>
        </Router>
      </ThemeContextProvider>
    </LangContextProvider>
  );
};

export default App;
