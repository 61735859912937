const theme = {
  colors: {
    text: {
      primary: '#3C4043',
      grey: '#cbcbcb',
    },
    palette: {
      primary: '#fff',
      secondary: '#c8fff773',
      lightPrimary: '#fff',
    },
    background: {
      primary: `#fff`,
    },
    grey: {
      medium: '#808080',
      light: '#cbcbcb',
    },
  },
  fontFamilies: {
    body: `Prompt, sans-serif`,
    title: `Prompt, sans-serif`,
  },
  fontWeights: {
    body: 300,
    title: 500,
  },
};

export default theme;
