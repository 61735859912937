import {
  deepOrange,
  deepPurple,
  cyan,
  lightGreen,
  teal,
  lightBlue,
  grey,
  green,
  amber,
} from '@material-ui/core/colors';

// can move to shared folder
const getColorByIndex = (index: number) => {
  const allColors = [
    teal[400],
    amber[700],
    lightGreen[700],
    cyan[700],
    green[700],
    lightBlue[500],
    grey[500],
    deepOrange[500],
    deepPurple[500],
  ];
  const i =
    index > allColors.length - 1 ? index % allColors.length : index;
  return allColors[i];
};

export default getColorByIndex;
