import React, { createContext } from 'react'
import useCurrentLocation, { DEFAULT_COORDS } from '../custom-hooks/useCurrentLocation'

interface CurrentLocationContextValue {
  currentCoords: { lat: number, lng: number },
  reReadCurrentLocation: () => void
}
export const CurrentLocationContext = createContext<CurrentLocationContextValue>({
  currentCoords: DEFAULT_COORDS,
  reReadCurrentLocation: () => { }
})
const CurrentLocationContextProvider: React.FC = (props) => {
  const { coords, reReadCurrentLocation } = useCurrentLocation()
  return (
    <CurrentLocationContext.Provider value={{ currentCoords: coords, reReadCurrentLocation }}>
      {props.children}
    </CurrentLocationContext.Provider>

  )
}

export default CurrentLocationContextProvider

/**
 * so it looks like it's in the middle even when the drawer is up
 * TODO: consider zoom level
 * @param coords
 */
export const translateScreenCenterDown = (coords: { lat: number, lng: number }) => {
  return {
    lat: coords.lat - 0.0010,
    lng: coords.lng
  }
}
